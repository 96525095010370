import React from 'react'
import "./loading.css";

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading"></div>
    </div>
  )
}

export default Loading